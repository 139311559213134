/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import React, { useEffect, useState, useMemo, useContext } from 'react';
import { string, arrayOf, shape, bool, number } from 'prop-types';

import classnames from 'classnames';
import Image from 'nordic/image';
import CarouselSnapped, { CarouselSnappedSlide } from '@andes/carousel-snapped';
import Button, { ButtonText } from '@andes/button';

import BillboardAttributes from '../../billboard/attributes/attributes.desktop';
import ItemPrice from '../../card/price';
import ItemBookmark from '../../bookmark/bookmark';
import { trackEvent } from '../../../lib/tracking';
import { StaticPropsContext } from '../../context/static-props';
import { useSearch } from '../../../hooks/context';

const namespace = 'ui-search-billboard';

const LAZYLOAD_ON = 'on';
const LAZYLOAD_OFF = 'off';

function trackingClick(tracks) {
  trackEvent(tracks);
}

const renderLogo = (logo, type, action, lowEnd, title, index = 0) => (
  <a
    href={action.target}
    className={`${namespace}__logo-image-link`}
    onClick={() => {
      trackingClick(action.tracks);
    }}
  >
    <figure className={`${namespace}__logo-image-container ${namespace}__logo-image-container--${type.toLowerCase()}`}>
      <Image
        className={`${namespace}__logo-image ${namespace}__logo-image--${type.toLowerCase()}`}
        src={logo}
        preload={index === 0}
        lazyload={lowEnd ? LAZYLOAD_OFF : LAZYLOAD_ON}
        alt={title}
      />
    </figure>
  </a>
);

const renderTitle = (title, action, type) => (
  <h2 className={`${namespace}__title ${namespace}__title--${type}`}>
    <a
      href={action.target}
      className={`${namespace}__link`}
      onClick={() => {
        trackingClick(action.tracks);
      }}
    >
      {title}
    </a>
  </h2>
);

const renderPrice = (price, type) => (
  <ItemPrice className={`${namespace}__price ${namespace}__price--${type}`} {...price} />
);

const ActionButton = React.memo(({ action }) => (
  <Button
    href={action.target}
    hierarchy="loud"
    size="medium"
    className={`${namespace}__action-button`}
    onClick={() => {
      trackingClick(action.tracks);
    }}
  >
    <ButtonText>{action?.label?.text}</ButtonText>
  </Button>
));

const VerticalContentRenderer = ({ item, type, lowEnd, index }) => {
  const { title, specifications, strapline, from, price, action } = item;

  return (
    <div className={`${namespace}__content`}>
      <div className={`${namespace}__header`}>
        {item.logo_url && renderLogo(item.logo_url, type, action, lowEnd, title, index)}
        <div className={`${namespace}__header-info`}>
          {strapline && <span className={`${namespace}__strapline`}>{strapline.text}</span>}
          {renderTitle(title, action, type.toLowerCase())}
        </div>
      </div>
      {from && <span className={`${namespace}__from`}>{from.text}</span>}
      {renderPrice(price, type.toLowerCase())}
      {specifications && (
        <BillboardAttributes
          attributes={specifications.map((attr) => ({ id: attr.id, label: attr.name, data: attr.value_name }))}
        />
      )}
      <ActionButton action={action} />
    </div>
  );
};

const SlideBillboard = ({ item, index, lowEnd, type, showUserBookmarks }) => {
  const { id, image_url, title } = item;

  return (
    <CarouselSnappedSlide className={`${namespace}__card`} key={id}>
      <Image
        className={`${namespace}__background`}
        src={image_url}
        preload={index === 0}
        lazyload={index === 0 ? LAZYLOAD_OFF : LAZYLOAD_ON}
        carousel
        alt={title}
      />
      {showUserBookmarks && <ItemBookmark className={`${namespace}__bookmark`} item={item} />}
      <VerticalContentRenderer item={item} type={type} lowEnd={lowEnd} index={index} />
    </CarouselSnappedSlide>
  );
};
const Billboard = ({ intervention }) => {
  const { contents: items, type } = intervention;
  const [isCollapsed, setCollapsed] = useState(false);
  const { lowEnd } = useContext(StaticPropsContext);
  const { bookmark } = useSearch();
  const showUserBookmarks = bookmark?.show_user_bookmarks;

  const classNames = classnames('ui-search-carousel--billboard', {
    'ui-search-carousel--billboard--lowend': lowEnd,
    'ui-search-carousel--collapsed': isCollapsed,
  });

  const toggleCollapseBillBoard = classnames('ui-search-carousel__btn-collapse', {
    'ui-search-carousel__btn-collapsed': isCollapsed,
  });

  useEffect(() => {
    if (items.length) {
      trackEvent(items[0].tracks);
    }
  });

  const sliderMemo = useMemo(
    () => (
      <CarouselSnapped
        arrows={{ size: 'large' }}
        spacing={0}
        type="full"
        pagination={false}
        lazyload
        beforeChange={(index) => {
          const track = items[index].tracks;

          trackEvent(track);
        }}
      >
        {items.map((item, index) => (
          <SlideBillboard item={item} index={index} lowEnd={lowEnd} type={type} showUserBookmarks={showUserBookmarks} />
        ))}
      </CarouselSnapped>
    ),
    [items],
  );

  return (
    <div className={classNames}>
      {sliderMemo}
      <button
        className={toggleCollapseBillBoard}
        type="button"
        aria-label="reduce carrousel"
        tabIndex="0"
        onClick={() => {
          setCollapsed(!isCollapsed);
        }}
      />
    </div>
  );
};

Billboard.propTypes = {
  intervention: shape({
    items: arrayOf(
      shape({
        id: string.isRequired,
        title: string.isRequired,
        image_url: string.isRequired,
        logo_url: string.isRequired,
        bookmarked: bool.isRequired,
        price: shape({
          amount: number.isRequired,
          currency_id: string.isRequired,
        }).isRequired,
        specifications: arrayOf(
          shape({
            id: string.isRequired,
            name: string.isRequired,
            value_name: string.isRequired,
          }),
        ),
        delivered_date: shape({
          text: string.isRequired,
        }),
        from: shape({
          text: string.isRequired,
        }),
        strapline: shape({
          text: string.isRequired,
        }),
        tracks: shape({
          melidata_track: shape({
            path: string.isRequired,
            type: string.isRequired,
            event_data: shape({
              item_id: string.isRequired,
              is_new_billboard: string.isRequired,
              position_shown: string.isRequired,
            }).isRequired,
          }),
        }).isRequired,
        action: shape({
          target: string.isRequired,
          label: shape({
            text: string.isRequired,
          }),
          tracks: shape({
            melidata_track: shape({
              path: string.isRequired,
              type: string.isRequired,
              event_data: shape({
                item_id: string.isRequired,
                is_new_billboard: bool.isRequired,
                position_shown: string.isRequired,
              }).isRequired,
            }),
          }).isRequired,
        }).isRequired,
      }),
    ).isRequired,
    type: string.isRequired,
  }),
};

Billboard.defaultProps = {
  intervention: {
    items: [],
    lowEnd: true,
    showUserBookmarks: true,
  },
};
Billboard.displayName = 'Billboard';
ActionButton.displayName = 'ActionButton';

export default Billboard;
