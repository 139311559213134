import React from 'react';
import { shape } from 'prop-types';

import Image from 'nordic/image';
import classnames from 'classnames';
import CarouselSnapped, { CarouselSnappedSlide } from '@andes/carousel-snapped';
import { Button, ButtonText } from '@andes/button';

import ItemBookmark from '../../bookmark/bookmark';
import Price from '../../card/price';
import { trackEvent } from '../../../lib/tracking';

const namespace = 'ui-search-billboard-intervention';

const VERTICAL_TYPE_MOT = 'MOT';
const VERTICAL_TYPE_RES = 'RES';

// eslint-disable-next-line react/prop-types
const LabelComponent = ({ text, color, font_size, font_family }) => {
  const classNames = classnames(
    `${namespace}__content-label--${font_size}`,
    `${namespace}__content-label--${color}`,
    `${namespace}__content-label--${font_family}`,
  );

  return <span className={classNames}>{text}</span>;
};

// eslint-disable-next-line react/prop-types
const PriceComponent = ({ amount, currency_id, color, size, thousandSeparator }) => {
  const classNames = classnames(`${namespace}__content-label--${size}`, `${namespace}__content-label--${color}`);

  return (
    <span className={classNames}>
      <Price amount={amount} currency_id={currency_id} size={size} thousandSeparator={thousandSeparator} />
    </span>
  );
};

const BillboardIntervention = ({ intervention }) => {
  const items = intervention.contents;

  let { vertical } = items[0];

  if (vertical === VERTICAL_TYPE_MOT) {
    vertical = classnames(`${namespace}__${vertical}`);
  }

  return (
    <div className={namespace}>
      <CarouselSnapped
        srLabel=""
        className={vertical === VERTICAL_TYPE_RES ? '' : vertical}
        arrows={{ visibility: 'hover' }}
        type="content"
        pagination={{
          mode: 'light',
          position: 'bottom',
        }}
        spacing={12}
        strictBoundaries={false}
        afterChange={(index) => {
          trackEvent(items[index].tracks);
        }}
      >
        {Object.values(items).map((item, i) => (
          <CarouselSnappedSlide key={`carousel-snapped-${item.id}`}>
            <div className="billboard-intervention__slide--card-top">
              <figure className="billboard-intervention__logo-image-container">
                <Image src={item.logo_url} alt="Logo" className="billboard-intervention__slide--card-top--logo" />
              </figure>
              <ItemBookmark className="ui-search-billboard__bookmark" item={item} />
            </div>
            <Image
              src={item.image_url}
              alt={item.image_alt_text}
              className="billboard-intervention__slide--card-img"
              preload={i < 2}
              lazyload={i < 2 ? 'off' : 'on'}
            />
            <div className={`${namespace}__content`}>
              {item.component_description.map((value) => {
                if (value.id === 'LABEL_COMPONENT') {
                  return <LabelComponent {...value} />;
                }

                return <PriceComponent {...value} />;
              })}
              <Button
                href={item.action.target}
                fullWidth
                size="medium"
                className={`${namespace}__action-button`}
                onClick={() => {
                  trackEvent(item.action.tracks);
                }}
              >
                <ButtonText>{item.action.label.text}</ButtonText>
              </Button>
            </div>
          </CarouselSnappedSlide>
        ))}
      </CarouselSnapped>
    </div>
  );
};

export default BillboardIntervention;

BillboardIntervention.propTypes = {
  intervention: shape({}),
};

BillboardIntervention.defaultProps = {
  intervention: null,
};
