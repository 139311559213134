import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-billboard-attributes';
const attrClassName = `${namespace}__attribute`;

const Attributes = ({ className, attributes }) => {
  if (attributes.length <= 0) {
    return null;
  }

  const ulClassNames = classnames(
    namespace,
    {
      [`${namespace}--three-cols`]: attributes.length > 2,
    },
    className,
  );

  const liClassNames = classnames(attrClassName, {
    [`${attrClassName}--auto-width`]: attributes.length > 2,
  });

  return (
    <ul className={ulClassNames}>
      {attributes.map((attribute) => (
        <li className={liClassNames} key={attribute.id}>
          <span className={`${namespace}__label`}>{attribute.label}</span>
          <span className={`${namespace}__data`}>{attribute.data}</span>
        </li>
      ))}
    </ul>
  );
};

Attributes.defaultProps = {
  attributes: [],
  className: null,
};

Attributes.propTypes = {
  attributes: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      data: string.isRequired,
    }),
  ),
  className: string,
};

export default Attributes;
